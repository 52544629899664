import * as actionTypes from "../types";

const inititalState = {
  nftList: [],
  traitTypes: [],
  traitIds: [],
  filter: "all",
  selectedNFT: null,
  cache: false,
  page: 1,
};

function uiReducer(state = inititalState, action) {
  switch (action.type) {
    case actionTypes.SET_NFT_LIST:
      return {
        ...state,
        nftList: action.payload,
        cache: true,
      };
    case actionTypes.SET_TRAIT_TYPES:
      return {
        ...state,
        traitTypes: action.payload,
      };
    case actionTypes.SET_TRAIT_IDS:
      return {
        ...state,
        traitIds: action.payload,
        page: 1,
        cache: false,
      };
    case actionTypes.CLEAR_FILTER:
      return {
        ...state,
        traitIds: [],
        filter: "all",
        page: 1,
        cache: false,
      };
    case actionTypes.SELECT_NFT:
      return {
        ...state,
        selectedNFT: action.payload,
        // cache: true,
      };
    case actionTypes.SET_MINTED_FILTER:
      return {
        ...state,
        filter: action.payload,
        cache: false,
      };
    case actionTypes.SET_PAGE:
      return {
        ...state,
        page: action.payload,
        cache: false,
      };
    default:
      return {
        ...state,
      };
  }
}

export default uiReducer;
