import { Box, ButtonBase, Checkbox, Typography } from "@mui/material";
import React, { useEffect } from "react";

import { ReactComponent as RightIcon } from "../assets/svg/caret-right-solid.svg";
import { ReactComponent as DownIcon } from "../assets/svg/caret-down-solid.svg";

import { useSelector, useDispatch } from "react-redux";
import {
  addTraitId,
  removeTraitId,
  toggleCheckBoxTraitTypes,
  toggleCheckBoxTraitValues,
  getTraitTypes,
} from "../redux/actions/nftActions";
const style = {
  icon: {
    height: "18px",
    marginRight: "8px",
  },
  checkboxContainer: {
    display: "flex",
    alignItems: "center",
  },
};
export default function CollapseContainer() {
  const dispatch = useDispatch();
  const { traitIds, traitTypes } = useSelector((state) => state.nftReducer);

  useEffect(() => {
    if (traitIds.length === 0) {
      dispatch(getTraitTypes());
    }
  }, [dispatch, traitIds]);

  const checkBoxOnChange = (id, index, valueIndex) => (e) => {
    if (e.target.checked) {
      dispatch(addTraitId(traitIds, id));
      dispatch(
        toggleCheckBoxTraitValues(
          traitTypes,
          index,
          valueIndex,
          e.target.checked
        )
      );
    } else {
      dispatch(removeTraitId(traitIds, id));
      dispatch(
        toggleCheckBoxTraitValues(
          traitTypes,
          index,
          valueIndex,
          e.target.checked
        )
      );
    }
  };

  const setOpen = (index) => {
    dispatch(toggleCheckBoxTraitTypes(traitTypes, index));
  };
  return (
    <Box>
      {traitTypes.length !== 0 &&
        traitTypes.rows.map((row, i) => (
          <Box key={i} sx={{ marginTop: "12px" }}>
            <ButtonBase onClick={() => setOpen(i)}>
              {row.show ? (
                <DownIcon style={style.icon} />
              ) : (
                <RightIcon style={style.icon} />
              )}
              <Typography
                variant="h4"
                sx={{
                  fontFamily: "EightBitOperator",
                  color: (theme) => theme.palette.text.t2,
                }}
              >
                {row.trait_type}
              </Typography>
            </ButtonBase>
            <Box sx={{ display: row.show ? "block" : "none" }}>
              {row.values.map((row2, i2) => (
                <Box key={i2} sx={style.checkboxContainer}>
                  <Checkbox
                    onChange={checkBoxOnChange(row2.id, i, i2)}
                    checked={
                      traitIds.length === 0
                        ? false
                        : row2.checked
                        ? row2.checked
                        : false
                    }
                  />
                  <Typography
                    variant="p"
                    sx={{
                      fontFamily: "EightBitOperator",
                      color: (theme) => theme.palette.text.t2,
                    }}
                  >
                    {row2.trait}
                  </Typography>
                </Box>
              ))}
            </Box>
          </Box>
        ))}
    </Box>
  );
}
