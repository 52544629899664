import * as actionTypes from "../types";

const inititalState = {
  isDarkMode: true,
  isLoading: false,
  helmet: {
    title: "Dinger Degens",
    description: "A 10,001 edition art collection in support of Dinger Token",
    image: null,
  },
};

function uiReducer(state = inititalState, action) {
  switch (action.type) {
    case actionTypes.SET_THEME:
      return {
        ...state,
        isDarkMode: action.payload,
      };
    case actionTypes.IS_LOADING:
      return {
        ...state,
        isLoading: action.payload,
      };
    case actionTypes.UPDATE_HELMET:
      return {
        ...state,
        helmet: action.payload,
      };
    default:
      return {
        ...state,
      };
  }
}

export default uiReducer;
