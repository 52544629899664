import React, { useEffect } from "react";
import {
  Box,
  Typography,
  Grid,
  Pagination,
  Skeleton,
  ButtonBase,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@mui/material";

// import Header from "../components/Header";
import NFTItem from "../components/NFTItem";

import { useSelector, useDispatch } from "react-redux";
import {
  getNFTList,
  resetTraitId,
  setMintedFilter,
  setPage,
} from "../redux/actions/nftActions";
import { updateHelmet } from "../redux/actions/uiActions";

import CollapseContainer from "../components/CollapseContainer";

import SampleNFT from "../assets/images/sample.png";

const style = {
  content: {
    padding: "24px",
    display: "flex",
    marginTop: "90px",
    flexDirection: {
      xs: "column",
      sm: "row",
    },
  },
  filterContainer: {
    backgroundColor: (theme) => theme.palette.background.b1,
    padding: "32px",
    minWidth: "300px",
    // height: "800px",
    borderRadius: "5px",

    boxShadow: "4px 4px 20px rgba(0, 0, 0, 0.4)",
    marginRight: {
      xs: 0,
      sm: "32px",
    },
  },
  NFTListContainer: {
    justifyContent: {
      xs: "center",
      sm: "flex-start",
    },
    marginTop: {
      xs: "12px",
      sm: 0,
    },
  },
  filterAction: {
    display: "flex",
    justifyContent: "space-between",
  },
  resetBtn: {
    fontFamily: "EightBitOperator",
    fontSize: "10px",
  },
};

export default function Home() {
  const dispatch = useDispatch();
  const { uiReducer, nftReducer } = useSelector((state) => state);
  const { nftList, traitIds, filter, cache, page, traitTypes } = nftReducer;
  const { isLoading } = uiReducer;

  useEffect(() => {
    dispatch(
      updateHelmet(
        "Dinger Degens",
        "A 10,001 edition art collection in support of Dinger Token",
        SampleNFT
      )
    );
    // console.log({ cache });
    if (!cache) {
      // console.log("test");
      // console.log({ page, traitIds, filter, traitTypes });
      dispatch(getNFTList({ page, traitIds, filter, traitTypes }));
    }
  }, [dispatch, traitIds, page, filter, cache, traitTypes]);

  const paginationOnChange = (e, page) => {
    dispatch(setPage(page));
  };
  const resetFilter = () => {
    dispatch(resetTraitId());
  };

  const filterOnChange = async (e) => {
    dispatch(setMintedFilter(e.target.value));
  };
  return (
    <Box>
      <Box sx={style.content}>
        <Box>
          <Box sx={style.filterContainer}>
            <Box sx={style.filterAction}>
              <Typography variant="h4">Filter</Typography>
              <ButtonBase sx={style.resetBtn} onClick={resetFilter}>
                Reset Filter
              </ButtonBase>
            </Box>

            <RadioGroup
              value={filter}
              onChange={filterOnChange}
              sx={{
                "& .MuiFormControlLabel-label": {
                  fontFamily: "EightBitOperator",
                  fontSize: "12px",
                },
                marginTop: "12px",
              }}
            >
              <FormControlLabel value="all" control={<Radio />} label="All" />
              <FormControlLabel
                value={false}
                control={<Radio />}
                label="Available"
              />
              <FormControlLabel
                value={true}
                control={<Radio />}
                label="Taken"
              />
            </RadioGroup>
            <Typography variant="h4" sx={{ marginTop: "12px" }}>
              Traits
            </Typography>
            <CollapseContainer />
            {/* {traitTypes.length !== 0 &&
              traitTypes.rows.map((row, i) => (
                <CollapseContainer
                  key={i}
                  data={row}
                  sx={{ marginTop: "12px" }}
                />
              ))} */}
          </Box>
        </Box>

        <Box>
          <Typography variant="h2" sx={{ marginTop: { xs: "24px", sm: 0 } }}>
            {nftList.length !== 0 && nftList.totalItems + " Degens"}
          </Typography>
          <Grid container sx={style.NFTListContainer} spacing={3}>
            {nftList.length !== 0 && !isLoading
              ? nftList.rows.map((row, i) => (
                  <Grid item key={i}>
                    <NFTItem data={row} />
                  </Grid>
                ))
              : [...Array(9)].map((e, i) => (
                  <Grid item key={i}>
                    <Skeleton
                      variant="rectangular"
                      width={320}
                      height={350}
                      sx={{ borderRadius: "15px", padding: "24px" }}
                    />
                  </Grid>
                ))}
            <Grid
              item
              container
              justifyContent="center"
              sx={{ marginTop: "24px" }}
            >
              <Pagination
                count={nftList.totalPages}
                page={page}
                onChange={paginationOnChange}
                color="primary"
              />
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Box>
  );
}
