import React, { useEffect } from "react";
import {
  AppBar,
  Box,
  Button,
  ButtonBase,
  Toolbar,
  Link,
  Dialog,
  Typography,
  IconButton,
} from "@mui/material";

import { useDispatch, useSelector } from "react-redux";
import {
  setWalletDialog,
  walletConnect,
  connectMetaMask,
} from "../redux/actions/etherActions";

import Logo from "../assets/images/logo.png";

import { ReactComponent as CloseIcon } from "../assets/svg/close-circle.svg";
import { ReactComponent as MetamaskIcon } from "../assets/svg/metamask.svg";
import { ReactComponent as WalletConnect } from "../assets/svg/walletconnect-logo.svg";
const style = {
  btnWalletConnect: {
    textTransform: "none",
  },
};
export default function Header() {
  const dispatch = useDispatch();
  const { account, openDialog } = useSelector((state) => state.etherReducer);

  useEffect(() => {
    // dispatch(connectMetaMask());
  }, [dispatch]);

  const connectMetamaskFunc = (e) => {
    dispatch(connectMetaMask());
  };

  const walletConnectFunc = (e) => {
    dispatch(walletConnect());
  };

  const handleClose = () => {
    dispatch(setWalletDialog(false));
  };

  const openDialogFunc = () => {
    dispatch(setWalletDialog(true));
  };
  return (
    <Box>
      <AppBar
        position="fixed"
        sx={{
          padding: "12px 0",
          background: (theme) => theme.palette.background.default,
        }}
      >
        <Toolbar sx={{ justifyContent: "space-between" }}>
          <ButtonBase
            component={Link}
            href="https://www.dingerdegens.com/EXPLORE"
          >
            <Box
              component="img"
              src={Logo}
              alt="logo"
              sx={{ height: "50px" }}
            />
          </ButtonBase>

          <Button
            variant="outlined"
            sx={style.btnWalletConnect}
            onClick={openDialogFunc}
          >
            {account
              ? `${account.substring(0, 4)}...${account.substring(
                  account.length - 4,
                  account.length
                )}`
              : "Connect Wallet"}
          </Button>
        </Toolbar>
      </AppBar>
      <Dialog
        open={openDialog}
        onClose={handleClose}
        sx={{
          "& .MuiDialog-paper": {
            borderRadius: "20px",
            padding: "24px",
          },
        }}
      >
        <Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography variant="h3">Wallet Options</Typography>
            <IconButton onClick={handleClose}>
              <CloseIcon style={{ height: "18px", width: "18px" }} />
            </IconButton>
          </Box>

          <Box
            sx={{
              height: "250px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <Button variant="outlined" onClick={connectMetamaskFunc}>
              <MetamaskIcon
                style={{ width: "24px", height: "24px", marginRight: "12px" }}
              />
              <Typography variant="h4">Connect to MetaMask</Typography>
            </Button>
            <Button
              variant="outlined"
              sx={{ marginTop: "32px" }}
              onClick={walletConnectFunc}
            >
              <WalletConnect
                style={{ width: "24px", height: "24px", marginRight: "12px" }}
              />
              <Typography variant="h4">WalletConnect</Typography>
            </Button>
          </Box>
        </Box>
      </Dialog>
    </Box>
  );
}
