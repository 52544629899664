import * as actionTypes from "../types";

export const toggleTheme = (isDarkMode) => async (dispatch) => {
  try {
    localStorage.setItem("isDarkMode", isDarkMode);
    dispatch({ type: actionTypes.SET_THEME, payload: isDarkMode });
  } catch (err) {
    console.error(err);
  }
};

export const getTheme = () => async (dispatch) => {
  try {
    const isDarkMode =
      localStorage.getItem("isDarkMode") === "true" ? true : false;
    dispatch({ type: actionTypes.SET_THEME, payload: isDarkMode });
  } catch (err) {
    console.error(err);
  }
};

export const isLoading = (value) => async (dispatch) => {
  try {
    return dispatch({ type: actionTypes.IS_LOADING, payload: value });
  } catch (err) {
    console.error(err);
  }
};

export const updateHelmet = (title, description, image) => async (dispatch) => {
  try {
    return dispatch({
      type: actionTypes.UPDATE_HELMET,
      payload: { title, description, image },
    });
  } catch (err) {
    console.error(err);
  }
};
