import {
  Button,
  ButtonBase,
  Dialog,
  Grid,
  Skeleton,
  Typography,
  Link,
  Backdrop,
  CircularProgress,
} from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";

import { useParams, useNavigate } from "react-router-dom";
import { ReactComponent as BackIcon } from "../assets/svg/chevron-left-solid.svg";
// import Header from "../components/Header";

import { useSelector, useDispatch } from "react-redux";
import { getNFTInfo, updateNFTList } from "../redux/actions/nftActions";
import { setWalletDialog } from "../redux/actions/etherActions";
import { updateHelmet } from "../redux/actions/uiActions";

import * as ethers from "ethers";

import { ReactComponent as EtherscanLogo } from "../assets/svg/etherscan.svg";
import { ReactComponent as ErrorIcon } from "../assets/svg/error.svg";

import { whitelisted } from "../data/whitelisted";
const style = {
  content: {
    padding: {
      xs: "24px",
      sm: "64px",
    },
    marginTop: "64px",
  },
  icon: {
    height: "18px",
    marginRight: "8px",
  },
  image: {
    width: "100%",
    maxWidth: "600px",
    borderRadius: "10px",
  },
  propertyItem: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",

    borderStyle: "solid",
    borderWidth: "2px",
    borderColor: (theme) => theme.palette.accent,
    borderRadius: "5px",
    // backgroundColor: (theme) => theme.palette.background.b1,
    padding: "12px",
  },
};
export default function ViewPage() {
  const { tokenId } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { nftReducer, uiReducer, etherReducer } = useSelector((state) => state);

  const { selectedNFT, nftList } = nftReducer;
  const { isLoading } = uiReducer;

  useEffect(() => {
    dispatch(getNFTInfo({ tokenId, userAddress: etherReducer.account }));
  }, [dispatch, tokenId, etherReducer.account]);

  useEffect(() => {
    if (selectedNFT) {
      dispatch(
        updateHelmet(
          selectedNFT.name,
          selectedNFT.description,
          selectedNFT.image
        )
      );
    }
  }, [dispatch, selectedNFT]);

  const dialogInitialState = {
    open: false,
    backdrop: false,
    data: null,
    onError: false,
    errorMessage: null,
  };
  const [dialog, setDialog] = useState(dialogInitialState);
  const handleClose = () => {
    setDialog(dialogInitialState);
  };
  const mint = async (tokenId, price) => {
    const { provider, account } = etherReducer;
    if (provider) {
      try {
        setDialog((prevItem) => ({ ...prevItem, backdrop: true }));

        const signer = provider.getSigner();
        const NFTInstance = new ethers.Contract(
          process.env.REACT_APP_NFT_CONTRACT,
          ["function mintByTokenId(uint256 tokenId) public payable"],
          signer
        );

        let tx = await NFTInstance.mintByTokenId(tokenId, { value: price });
        setDialog((prevItem) => ({
          ...prevItem,
          open: true,
          backdrop: false,
          data: tx.hash,
        }));
        await tx.wait();

        dispatch(getNFTInfo({ tokenId, userAddress: etherReducer.account }));
        dispatch(updateNFTList(nftList, tokenId));

        // console.log(`Transaction hash: ${tx.hash}`);
        // const receipt = await tx.wait();
        // console.log(`Transaction was mined in block ${receipt.blockNumber}`);
      } catch (err) {
        console.error(err);
        let errorMessage = err.message;
        if (err.message.includes("Caller is not whitelisted")) {
          errorMessage = "You are not whitelisted.";
        } else if (err.message.includes("Sale is not open")) {
          errorMessage = "Sale is not open.";
        } else if (err.message.includes("insufficient funds")) {
          errorMessage = "Insufficient funds.";
        } else if (err.message.includes("Token Id has been claimed already")) {
          errorMessage = "NFT has been claimed already.";
        } else if (
          err.message.includes("NFT is already reserved to an owner")
        ) {
          errorMessage = "NFT is already reserved to an owner.";
        } else {
          errorMessage = "Error.";
        }

        setDialog((prevItem) => ({
          ...prevItem,
          onError: true,
          backdrop: false,
          errorMessage: errorMessage,
        }));
        setDialog((prevItem) => ({ ...prevItem, backdrop: false }));
      }
    } else {
      dispatch(setWalletDialog(true));
    }
  };
  return (
    <Box>
      <Box sx={style.content}>
        <ButtonBase onClick={() => navigate("/")}>
          <BackIcon style={style.icon} />
          <Typography variant="h4">Back</Typography>
        </ButtonBase>
        <Grid container spacing={3} sx={{ marginTop: "24px" }}>
          <Grid item md={5}>
            {isLoading || !selectedNFT ? (
              <Skeleton
                variant="rectangular"
                sx={{ ...style.image, paddingTop: "90%" }}
              />
            ) : (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Box
                  component="img"
                  src={selectedNFT.image}
                  alt={selectedNFT.token_id}
                  sx={style.image}
                />
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    flexDirection: {
                      xs: "column",
                      md: "row",
                    },
                    marginTop: "12px",
                  }}
                >
                  <Box sx={{ flex: 1 }}>
                    {selectedNFT.owner ? (
                      <>
                        <Typography
                          variant="h2"
                          sx={{
                            color: (theme) => theme.palette.accent,
                            fontFamily: "EightBitOperator",
                            fontSize: "16px",
                          }}
                        >
                          owner
                        </Typography>
                        <Typography variant="h2" sx={{ marginTop: "6px" }}>
                          {`${selectedNFT.owner.substring(
                            0,
                            4
                          )}...${selectedNFT.owner.substring(
                            selectedNFT.owner.length - 4,
                            selectedNFT.owner.length
                          )}`}
                        </Typography>
                      </>
                    ) : (
                      <>
                        <Typography
                          variant="h2"
                          sx={{
                            color: (theme) => theme.palette.accent,
                            fontFamily: "EightBitOperator",
                            fontSize: "16px",
                          }}
                        >
                          Price
                        </Typography>

                        <Typography variant="h2" sx={{ marginTop: "6px" }}>
                          {ethers.utils.formatEther(selectedNFT.price) > 0
                            ? `${ethers.utils.formatEther(
                                selectedNFT.price
                              )} ETH`
                            : "FREE"}
                        </Typography>
                      </>
                    )}
                  </Box>
                  <Box sx={{ flex: 1 }}>
                    {!selectedNFT.owner && (
                      <Button
                        variant="contained"
                        sx={{
                          height: "100%",
                          width: "100%",
                          padding: "12px",
                          color: (theme) => theme.palette.text.t1,
                          marginTop: {
                            xs: "12px",
                            md: "0",
                          },
                        }}
                        onClick={() =>
                          mint(selectedNFT.token_id, selectedNFT.price)
                        }
                      >
                        Mint
                      </Button>
                    )}
                  </Box>
                </Box>
              </Box>
            )}
          </Grid>
          <Grid item md={7}>
            <Box>
              {isLoading || !selectedNFT ? (
                <Box>
                  <Skeleton
                    variant="rectangular"
                    sx={{ borderRadius: "5px", maxWidth: "150px" }}
                  />
                  <Skeleton
                    variant="rectangular"
                    sx={{
                      borderRadius: "5px",
                      maxWidth: "350px",
                      marginTop: "4px",
                      height: "36px",
                    }}
                  />
                  <Skeleton
                    variant="rectangular"
                    sx={{
                      borderRadius: "5px",
                      maxWidth: "150px",
                      marginTop: "24px",
                    }}
                  />
                  <Skeleton
                    variant="rectangular"
                    sx={{
                      borderRadius: "5px",
                      marginTop: "12px",
                      height: "36px",
                    }}
                  />
                  <Skeleton
                    variant="rectangular"
                    sx={{
                      borderRadius: "5px",
                      maxWidth: "150px",
                      marginTop: "24px",
                    }}
                  />
                </Box>
              ) : (
                <Box>
                  <Typography
                    variant="h4"
                    sx={{
                      color: (theme) => theme.palette.accent,
                      fontFamily: "EightBitOperator",
                    }}
                  >
                    #{selectedNFT.token_id}
                  </Typography>
                  <Typography variant="h2" sx={{ marginTop: "4px" }}>
                    {selectedNFT.name}
                  </Typography>
                  <Typography
                    variant="h3"
                    sx={{
                      fontFamily: "EightBitOperator",
                      fontSize: "14px",
                      marginTop: "24px",
                    }}
                  >
                    Description
                  </Typography>
                  <Typography
                    variant="h3"
                    sx={{ marginTop: "12px" }}
                    style={{ whiteSpace: "pre-line" }}
                  >
                    {selectedNFT.description}
                  </Typography>

                  {/* lipat */}
                  <Typography
                    variant="h3"
                    sx={{
                      marginTop: "24px",
                      fontFamily: "EightBitOperator",
                      fontSize: "14px",
                    }}
                  >
                    Properties
                  </Typography>
                </Box>
              )}
              {isLoading || !selectedNFT ? (
                <Skeleton
                  variant="rectangular"
                  sx={{
                    borderRadius: "5px",
                    height: "500px",
                    marginTop: "12px",
                  }}
                />
              ) : (
                <Grid container sx={{ marginTop: "12px" }} spacing={2}>
                  {selectedNFT.nft_attributes.map((row, i) => (
                    <Grid item xs={4} md={4} key={i}>
                      <Box sx={style.propertyItem}>
                        <Typography variant="sh" sx={{ textAlign: "center" }}>
                          {row.trait.value}
                        </Typography>
                        <Typography
                          variant="p"
                          sx={{
                            fontFamily: "EightBitOperator",
                            textAlign: "center",
                            marginTop: "12px",
                          }}
                        >
                          {row.trait.trait_type}
                        </Typography>
                      </Box>
                    </Grid>
                  ))}
                </Grid>
              )}
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Dialog
        open={dialog.open}
        onClose={handleClose}
        sx={{
          "& .MuiDialog-paper": {
            borderRadius: "20px",
          },
        }}
      >
        <Box
          sx={{
            padding: "24px",
            minWidth: {
              xs: 0,
              sm: "400px",
            },
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: (theme) => theme.palette.background.b1,
          }}
        >
          <ButtonBase
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
            component={Link}
            underline="none"
            href={`https://etherscan.io/tx/${dialog.data}`}
            target="_blank"
            rel="noreferrer"
          >
            <EtherscanLogo style={{ height: "100px" }} />
            <Typography
              variant="h5"
              sx={{
                marginTop: "24px",
                fontFamily: "EightBitOperator",
                textAlign: "center",
              }}
            >
              View transaction in Etherscan
            </Typography>
          </ButtonBase>

          <Button
            variant="outlined"
            sx={{ marginTop: "24px" }}
            onClick={handleClose}
          >
            Close
          </Button>
        </Box>
      </Dialog>
      <Dialog
        open={dialog.onError}
        onClose={handleClose}
        sx={{
          "& .MuiDialog-paper": {
            borderRadius: "20px",
          },
        }}
      >
        <Box
          sx={{
            padding: "24px",
            display: "flex",
            minWidth: {
              xs: 0,
              sm: "400px",
            },
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: (theme) => theme.palette.background.b1,
          }}
        >
          <ErrorIcon style={{ height: "100px" }} />
          <Typography
            variant="h5"
            sx={{
              marginTop: "24px",
              fontFamily: "EightBitOperator",
              textAlign: "center",
            }}
          >
            {dialog.errorMessage}
          </Typography>

          <Button
            variant="outlined"
            sx={{ marginTop: "24px" }}
            onClick={handleClose}
          >
            Close
          </Button>
        </Box>
      </Dialog>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={dialog.backdrop}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </Box>
  );
}
