const config = {
  bg: {
    position: "fixed",
    zIndex: -1,
    // top: 0,
    // left: 0,
  },
  type: "custom",
  config: {
    num: [20, 50],
    rps: 0.1,
    radius: [1, 1],
    life: [1.5, 3],
    v: [2, 3],
    tha: [-40, 40],
    // body: "./img/icon.png", // Whether to render pictures
    // rotate: [0, 20],
    alpha: [0.1, 0],
    scale: [1, 20],
    position: "all", // all or center or {x:1,y:1,width:100,height:100}
    color: ["random"],
    cross: "dead", // cross or bround
    random: 15, // or null,
    g: 1, // gravity
    // f: [2, -1], // force
    onParticleUpdate: (ctx, particle) => {
      ctx.beginPath();
      ctx.rect(
        particle.p.x,
        particle.p.y,
        particle.radius * 0.15,
        particle.radius * 0.15
      );
      ctx.fillStyle = particle.color;
      ctx.fill();
      ctx.closePath();
    },
  },
};

export default config;
