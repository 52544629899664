import React from "react";
import { ButtonBase, Box, Typography } from "@mui/material";

import { motion } from "framer-motion";

import { useNavigate } from "react-router-dom";

export default function NFTItem({ data }) {
  const navigate = useNavigate();

  const selectNFT = (tokenId) => {
    navigate(`/${tokenId}`);
  };
  return (
    <ButtonBase
      component={motion.div}
      onClick={() => selectNFT(data.token_id)}
      whileHover={{
        translateY: [0, -15, 0],
        transition: { duration: 1, repeat: Infinity },
      }}
      sx={{
        borderStyle: "solid",
        borderWidth: "4px",
        borderColor: (theme) => theme.palette.accent,
        borderRadius: "15px",
        padding: "24px",
        backgroundColor: (theme) => theme.palette.background.b1,
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Box
        sx={{
          width: "280px",
          height: "280px",
          position: "relative",
        }}
      >
        <Box
          component="img"
          src={data.image}
          sx={{
            width: "100%",
            height: "100%",
            borderRadius: "10px",
          }}
        />
        {data.minted && (
          <Box
            sx={{
              position: "absolute",
              top: 0,
              width: "100%",
              height: "100%",
              background: (theme) => theme.palette.background.b3,
              borderRadius: "10px",
              opacity: "0.7",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Typography variant="h2">Taken</Typography>
          </Box>
        )}
      </Box>

      <Typography
        variant="h3"
        sx={{
          marginTop: "24px",
          fontFamily: "EightBitOperator",
        }}
      >
        {data.name.length > 20 ? data.name.substring(0, 20) + "..." : data.name}
      </Typography>
    </ButtonBase>
  );
}
