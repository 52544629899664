import React from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";

import { useSelector } from "react-redux";

export const HelmetComponent = (props) => {
  const { helmet } = useSelector((state) => state.uiReducer);
  return (
    <HelmetProvider>
      <Helmet>
        <title>{helmet.title}</title>
        <meta name="description" content={helmet.description} />
        <meta property="og:title" content={helmet.title} />
        <meta property="og:type" content="article" />
        <meta property="og:description" content={helmet.description} />
        <meta property="og:image" content={helmet.image} />
      </Helmet>
      {props.children}
    </HelmetProvider>
  );
};
