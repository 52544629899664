import React from "react";

import Router from "./router/Router";
import { Provider } from "react-redux";

import store from "./redux/store";

import { HelmetComponent } from "./components/HelmetComponent";
export default function App() {
  return (
    <Provider store={store}>
      <HelmetComponent>
        <Router />
      </HelmetComponent>
    </Provider>
  );
}
console.log("updated 29");