//UI REDUCER
export const SET_THEME = "SET_THEME";
export const IS_LOADING = "IS_LOADING";
export const UPDATE_HELMET = "UPDATE_HELMET";

//NFT REDUCER
export const SET_NFT_LIST = "SET_NFT_LIST";
export const SET_TRAIT_TYPES = "SET_TRAIT_TYPES";
export const SET_TRAIT_IDS = "SET_TRAIT_IDS";
export const CLEAR_FILTER = "CLEAR_FILTER";
export const SELECT_NFT = "SELECT_NFT";
export const SET_MINTED_FILTER = "SET_MINTED_FILTER";
export const SET_PAGE = "SET_PAGE";

//ETHER REDUCER
export const SET_PROVIDER = "SET_PROVIDER";
export const SET_ACCOUNT = "SET_ACCOUNT";
export const OPEN_DIALOG = "OPEN_DIALOG";
