import * as actionTypes from "../types";

const inititalState = {
  provider: null,
  walletOption: null,
  account: null,
  openDialog: false,
};

function uiReducer(state = inititalState, action) {
  switch (action.type) {
    case actionTypes.SET_PROVIDER:
      return {
        ...state,
        provider: action.payload.provider,
        walletOption: action.payload.walletOption,
        openDialog: false,
      };
    case actionTypes.SET_ACCOUNT:
      return {
        ...state,
        account: action.payload,
      };
    case actionTypes.OPEN_DIALOG:
      return {
        ...state,
        openDialog: action.payload,
      };
    default:
      return {
        ...state,
      };
  }
}

export default uiReducer;
