import PressStartK from "../assets/fonts/PressStartK.woff";
import EightBitOperator from "../assets/fonts/8bitoperator.woff";

const customFonts = `
  @font-face {
    font-family: 'PressStartK';
    src: url(${PressStartK}) format('woff');
  }
  @font-face {
    font-family: 'EightBitOperator';
    src: url(${EightBitOperator}) format('woff');
  }
`;

const darkScrollBar = `
  ::-webkit-scrollbar { width: 8px; height: 3px;}
  ::-webkit-scrollbar-track {  background-color: #646464;}
  ::-webkit-scrollbar-track-piece { background-color: #000;}
  ::-webkit-scrollbar-thumb { height: 50px; background-color: #666; border-radius: 3px;}
  ::-webkit-scrollbar-corner { background-color: #646464;}}
  ::-webkit-resizer { background-color: #666;}`;

const theme = (isDarkMode) => ({
  palette: {
    mode: isDarkMode ? "dark" : "dark", // change this to light later
    primary: {
      main: "#26CE8D",
      disable: "#2C2F31",
    },
    secondary: {
      main: "#26CE8D",
    },
    text: {
      primary: "#E6E6E6",
      t1: "#FFFFFF",
      t2: "#D1D4C9",
      t3: "#62666D",
    },
    accent: "#20B87D",
    error: {
      main: "#E03E65",
    },
    background: {
      default: "#0E0C17",
      b1: "#1E1F20",
      b2: "#131414",
      b3: "#303336",
      b4: "#090807",
    },
  },
  typography: {
    fontFamily: [
      "PressStartK",
      "EightBitOperator",
      "Rubik",
      "Open Sans",
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    h1: {
      fontStyle: "normal",
      fontWeight: "normal",
      fontSize: "30px",
      lineHeight: "40px",
    },
    h2: {
      fontStyle: "normal",
      fontWeight: "normal",
      fontSize: "24px",
      lineHeight: "24px",
    },
    h3: {
      fontStyle: "normal",
      fontWeight: "normal",
      fontSize: "18px",
      lineHeight: "20px",
    },
    h4: {
      fontStyle: "normal",
      fontWeight: "500",
      fontSize: "14px",
      lineHeight: "20px",
    },
    h5: {
      fontStyle: "normal",
      fontWeight: "normal",
      fontSize: "14px",
      lineHeight: "20px",
    },
    sh: {
      fontStyle: "normal",
      fontWeight: "500",
      fontSize: "12px",
      lineHeight: "16px",
    },
    p: {
      fontStyle: "normal",
      fontWeight: "300",
      fontSize: "12px",
      lineHeight: "16px",
    },
  },
  components: {
    MuiCssBaseline: {
      // styleOverrides: `
      //   @font-face {
      //     font-family: 'PressStartK';
      //     src: url(${PressStartK}) format('woff');
      //   }
      //   @font-face {
      //     font-family: 'EightBitOperator';
      //     src: url(${EightBitOperator}) format('woff');
      //   }
      //  `,
      styleOverrides: customFonts + darkScrollBar,
    },
  },
});

export default theme;
