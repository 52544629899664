import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import { ThemeProvider, createTheme } from "@mui/material";
import theme from "../utils/theme";
import CssBaseline from "@mui/material/CssBaseline";

import { useSelector } from "react-redux";

import Header from "../components/Header";
import Home from "../pages/Home";
import NotFound from "../pages/NotFound";
import ViewPage from "../pages/ViewPage";
// import Dinger from "../pages/Dinger";

import ScrollToTop from "../components/ScrollToTop";

import ParticlesBg from "particles-bg";
import particlesConfig from "../utils/particlesConfig";

import SocialLinks from "../components/SocialLinks";
export default function Router() {
  const uiReducer = useSelector((state) => state.uiReducer);
  const THEME = createTheme(theme(uiReducer.isDarkMode));
  return (
    <ThemeProvider theme={THEME}>
      <CssBaseline />
      <ParticlesBg {...particlesConfig} />
      <BrowserRouter>
        <ScrollToTop />
        <Header />
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route path={`/:tokenId`} element={<ViewPage />} />

          {/* <Route path="/dinger" element={<Dinger />} /> */}
          <Route path="*" element={<NotFound />} />
        </Routes>
      </BrowserRouter>
      <SocialLinks />
    </ThemeProvider>
  );
}
