import React from "react";

import { SpeedDial, SpeedDialAction, Link, Box } from "@mui/material";

import DingerIcon from "../assets/images/degen-head.png";

import { ReactComponent as Telegram } from "../assets/svg/telegram.svg";
import { ReactComponent as Twitter } from "../assets/svg/twitter.svg";
import { ReactComponent as Discord } from "../assets/svg/discord.svg";
import { ReactComponent as Opensea } from "../assets/svg/opensea.svg";

const style = {
  mainIcon: {
    height: "50px",
    // width: "60px",
  },
  icon: {
    height: "40px",
    width: "40px",
  },
  iconContainer: {
    height: "60px",
    width: "60px",
    color: (theme) => theme.palette.accent,
  },
};
const actions = [
  {
    icon: <Telegram style={style.icon} />,
    name: "Telegram",
    link: "https://t.me/dingertoken",
  },
  {
    icon: <Twitter style={style.icon} />,
    name: "Twitter",
    link: "https://twitter.com/dingertoken",
  },
  {
    icon: <Discord style={style.icon} />,
    name: "Discord",
    link: "https://discord.com/invite/u426hmda5T",
  },
  {
    icon: <Opensea style={style.icon} />,
    name: "Opensea",
    link: "https://opensea.io/collection/dinger-degens",
  },
];

export default function SocialLinks() {
  return (
    <SpeedDial
      ariaLabel="social links"
      sx={{
        position: "fixed",
        bottom: 16,
        right: 16,
        "& .MuiSpeedDial-fab": {
          background: "transparent",
        },
      }}
      icon={
        <Box
          component="img"
          alt="logo"
          src={DingerIcon}
          style={style.mainIcon}
        />
      }
    >
      {actions.map((action) => (
        <SpeedDialAction
          key={action.name}
          icon={action.icon}
          tooltipTitle={action.name}
          sx={style.iconContainer}
          component={Link}
          underline="none"
          href={action.link}
          target="_blank"
          rel="noreferrer"
        />
      ))}
    </SpeedDial>
  );
}
