import React from "react";
import { Box, Typography, Button } from "@mui/material";
import { Link } from "react-router-dom";

const style = {
  section: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "200px 5px",
  },
  text1: {
    textAlign: "center",
  },
  text2: {
    textAlign: "center",
  },
  text3: {
    textAlign: "center",
  },
  button: {
    marginTop: 5,
  },
};

export default function NotFound() {
  return (
    <Box>
      <Box sx={style.section}>
        <Typography variant="h1" sx={style.text1}>
          404
        </Typography>
        <Typography variant="h2" sx={style.text2}>
          There's NOTHING here...
        </Typography>
        <Typography variant="subtitle1" sx={style.text3}>
          ..maybe the page you're loooking for is not found or never existed.
        </Typography>
        <Button component={Link} to="/" variant="contained" sx={style.button}>
          Back to home
        </Button>
      </Box>
    </Box>
  );
}
