import * as ethers from "ethers";
import * as actionTypes from "../types";
import WalletConnectProvider from "@walletconnect/web3-provider";

export const connectMetaMask = () => async (dispatch) => {
  if (window.ethereum) {
    try {
      await window.ethereum.request({
        method: "wallet_switchEthereumChain",
        params: [{ chainId: "0x1" }], //Rinkeby "0x4", mainnet 0x1
      });

      const provider = new ethers.providers.Web3Provider(
        window.ethereum,
        "any"
      );
      // console.log(provider);
      await provider.send("eth_requestAccounts", []);
      const signer = provider.getSigner();
      const account = await signer.getAddress();

      await dispatch({
        type: actionTypes.SET_PROVIDER,
        payload: { provider: provider, walletOption: "metamask" },
      });
      await dispatch({
        type: actionTypes.SET_ACCOUNT,
        payload: account,
      });
    } catch (err) {
      throw err;
    }
  }
};

export const walletConnect = () => async (dispatch) => {
  try {
    const wConnect = new WalletConnectProvider({
      rpc: {
        1: "https://mainnet.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161",
        // 4: "wss://eth-rinkeby.alchemyapi.io/v2/py_-OoKy61fNNZqMc4VNjDaDoPmbS0D-",
        // 56: "wss://speedy-nodes-nyc.moralis.io/9fabf363d1719ebf25259a68/bsc/mainnet/ws",
      },
      // infuraId: "27e484dcd9e3efcfd25a83a78777cdf1",
      qrcodeModalOptions: {
        mobileLinks: [
          "rainbow",
          "metamask",
          "argent",
          "trust",
          "imtoken",
          "pillar",
        ],
      },
    });
    await wConnect.enable();

    const provider = new ethers.providers.Web3Provider(wConnect, "any");
    // console.log(provider);

    // await provider.send("eth_requestAccounts", []);
    const signer = provider.getSigner();
    const account = await signer.getAddress();

    // console.log(account);
    await dispatch({
      type: actionTypes.SET_PROVIDER,
      payload: { provider, walletOption: "connectwallet" },
    });
    await dispatch({
      type: actionTypes.SET_ACCOUNT,
      payload: account,
    });
    // console.log("wew");
  } catch (err) {
    console.error(err);
  }
};

export const setWalletDialog = (state) => async (dispatch) => {
  await dispatch({
    type: actionTypes.OPEN_DIALOG,
    payload: state,
  });
};
