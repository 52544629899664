import * as actionTypes from "../types";
import axios from "../../utils/axios";

import _ from "lodash";

export const getNFTList =
  ({ page, traitIds, filter, traitTypes }) =>
  async (dispatch) => {
    try {
      let parseFilter = "all";
      if (filter === "true") {
        parseFilter = true;
      } else if (filter === "false") {
        parseFilter = false;
      }
      await dispatch({
        type: actionTypes.IS_LOADING,
        payload: true,
      });

      //get and count for trait types
      let traitAnd = 0;
      //TODO: change reducer to {} instead of []
      if (traitTypes.length !== 0) {
        for (let row of traitTypes.rows) {
          if (row.filterCount !== undefined && row.filterCount > 0) {
            traitAnd += 1;
            // console.log(row);
          }
        }
      }

      const apiResult = await axios.post(`/api/nfts?size=12&page=${page}`, {
        traitIds,
        traitAnd,
        filter: parseFilter,
      });

      // console.log(apiResult.data);

      await dispatch({
        type: actionTypes.SET_NFT_LIST,
        payload: apiResult.data,
      });
      await dispatch({
        type: actionTypes.IS_LOADING,
        payload: false,
      });
    } catch (err) {
      dispatch({
        type: actionTypes.IS_LOADING,
        payload: false,
      });
      console.error(err);
    }
  };

export const updateNFTList = (array, tokenId) => async (dispatch) => {
  let newRows = [];

  if (array.length !== 0) {
    for (let row of array.rows) {
      if (row.token_id === tokenId) {
        row.minted = true;
      }
      newRows.push(row);
    }

    array.rows = newRows;
    await dispatch({
      type: actionTypes.SET_NFT_LIST,
      payload: array,
    });
  }
};

export const getTraitTypes = () => async (dispatch) => {
  try {
    const apiResult = await axios.get(`/api/nft/trait_types?size=200`);

    // console.log(apiResult.data);

    await dispatch({
      type: actionTypes.SET_TRAIT_TYPES,
      payload: apiResult.data,
    });
  } catch (err) {
    console.error(err);
  }
};

export const setPage = (page) => async (dispatch) => {
  try {
    await dispatch({
      type: actionTypes.SET_PAGE,
      payload: page,
    });
  } catch (err) {
    console.error(err);
  }
};

export const toggleCheckBoxTraitTypes = (array, index) => async (dispatch) => {
  try {
    if (array.rows[index].show !== undefined) {
      array.rows[index].show = !array.rows[index].show;
    } else {
      array.rows[index].show = true;
    }

    await dispatch({
      type: actionTypes.SET_TRAIT_TYPES,
      payload: array,
    });
  } catch (err) {
    console.error(err);
  }
};
export const toggleCheckBoxTraitValues =
  (array, index, valueIndex, value) => async (dispatch) => {
    try {
      // console.log({ index, valueIndex, value });
      // console.log(array);
      array.rows[index].values[valueIndex].checked = value;

      if (array.rows[index].filterCount === undefined) {
        array.rows[index].filterCount = 0;
      }
      if (value) {
        array.rows[index].filterCount++;
      } else {
        array.rows[index].filterCount--;
      }

      await dispatch({
        type: actionTypes.SET_TRAIT_TYPES,
        payload: array,
      });
    } catch (err) {
      console.error(err);
    }
  };

export const addTraitId = (array, id) => async (dispatch) => {
  try {
    const newFilter = array;
    newFilter.push(id);

    await dispatch({
      type: actionTypes.SET_TRAIT_IDS,
      payload: newFilter,
    });
  } catch (err) {
    console.error(err);
  }
};

export const removeTraitId = (array, id) => async (dispatch) => {
  try {
    const newFilter = array;
    _.remove(newFilter, function (n) {
      return n === id;
    });

    await dispatch({
      type: actionTypes.SET_TRAIT_IDS,
      payload: newFilter,
    });
  } catch (err) {
    console.error(err);
  }
};

export const resetTraitId = () => async (dispatch) => {
  try {
    await dispatch({
      type: actionTypes.CLEAR_FILTER,
    });
    await dispatch(getNFTList({ page: 1, traitIds: [] }));
  } catch (err) {
    console.error(err);
  }
};

export const getNFTInfo =
  ({ tokenId, userAddress }) =>
  async (dispatch) => {
    try {
      await dispatch({
        type: actionTypes.IS_LOADING,
        payload: true,
      });

      let apiResult;
      if (userAddress) {
        apiResult = await axios.get(
          `/api/nfts/${tokenId}?userAddress=${userAddress}`
        );
      } else {
        apiResult = await axios.get(`/api/nfts/${tokenId}`);
      }

      // console.log(apiResult.data);

      await dispatch({
        type: actionTypes.SELECT_NFT,
        payload: apiResult.data,
      });
      await dispatch({
        type: actionTypes.IS_LOADING,
        payload: false,
      });
    } catch (err) {
      dispatch({
        type: actionTypes.IS_LOADING,
        payload: false,
      });
      console.error(err);
    }
  };

export const setMintedFilter = (value) => async (dispatch) => {
  try {
    // console.log(value);
    await dispatch({
      type: actionTypes.SET_MINTED_FILTER,
      payload: value,
    });
  } catch (err) {
    console.error(err);
  }
};
